import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`

const PageLink = styled(NavLink)`
  &:link, &:visited {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: 1;
    margin: 0 8px;
    display: flex;
    color: #6bb500;
  }
`

const PageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 1;
  margin: 0 8px;
  display: flex;
  color: white;
  background-color: #6bb500;
`

export default function Pagination(props) {
  const { totalPages, currentPage } = props
  const pages = []

  for (let index = 1; index <= totalPages; index++) {
    pages.push(index)
  }

  return (
    <List>
      {pages.map(page => {
        const isCurrentPage = parseInt(currentPage, 10) === page
        const template = isCurrentPage ? <PageText>{page}</PageText> : <PageLink to={`/blog/${page}`}>{page}</PageLink>
        return <li key={`pagination_${page}`}>
          {template}
        </li>
      })}
    </List>
  )
}
