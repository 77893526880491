import React from 'react'
import styled, { css } from 'styled-components'
import anna from '../assets/anna.jpeg'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin:  0 0 30px;

  @media (max-width: 1000px) {
    font-size: 26px;
    margin:  0 0 20px;
  }
`

const Paragraph = styled.p`
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 1.5;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`

const Quote = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 40px;
  line-height: 1.5;
  border-radius: 12px;
  background-color: #f2f2f2;
`

const Column = styled.div`
  flex: 1;
  &:first-child {
    max-width: 35%;
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    &:first-child {
      margin-right: 0;
      max-width: none;
    }
  }
`

const Image = styled.img`
  border-radius: 8px;
  width: 100%;

  ${props => props.onlyDesktop ? css`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  ` : null}

  ${props => props.onlyMobile ? css`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin-bottom: 24px;
  }
  ` : null}
`

export default function ChiSono() {
  return (
    <Wrapper>
      <Column>
        <Image src={anna} onlyDesktop alt="Anna Petruzza" />
        <Quote>“That which is created in a relationship, can be fixed in a relationship.”<br /><br />- Murray Bowen</Quote>
      </Column>
      <Column>
        <Title>Chi Sono</Title>
        <Image src={anna} onlyMobile alt="Anna Petruzza" />
        <Paragraph>Mi sono laureata in Psicologia Clinica con 110 e Lode, presso l’Università degli studi di Roma “La Sapienza”.</Paragraph>
        <Paragraph>Successivamente, ho collaborato con il reparto di Psichiatria dell’Ospedale Sant’Andrea di Roma, occupandomi prevalentemente di <strong>psicodiagnostica</strong>; ho inoltre seguito, con grande coinvolgimento, un progetto di prevenzione psicologica per <strong>donne in gravidanza e neo-mamme</strong>, interessandomi molto al delicato tema della <strong>maternità</strong>.</Paragraph>
        <Paragraph>Ho deciso di proseguire il mio percorso formativo attraverso un corso di <strong>specializzazione in psicoterapia sistemico relazionale</strong> presso l’Accademia di Psicoterapia della Famiglia di Roma, diretta dal Prof. Maurizio Andolfi.</Paragraph>
        <Paragraph>Mi sono poi trasferita a Milano, dove attualmente vivo. Qui ho collaborato con scuole di diverso ordine e grado, occupandomi di <strong>DSA, ADHD, psicologia infantile e adolescenti</strong>.</Paragraph>
        <Paragraph>Collaboro come psicologa in un consultorio familiare, in cui vengono svolte <strong>consultazioni psicologiche individuali, di coppia e familiari</strong>, nei diversi momenti del ciclo di vita, offrendo risposte a situazioni di difficoltà su come affrontare cambiamenti nei propri percorsi di vita e di relazione.</Paragraph>
        <Paragraph>Lavoro come psicologa per un’associazione che si occupa di disabilità, offrendo sostegno psicologico a bambini e ragazzi, e alle loro famiglie.</Paragraph>
        <Paragraph>Svolgo l’attività da libero professionista nel mio studio a Milano, in Via San Gregorio 6, in zona Porta Venezia - Repubblica.</Paragraph>
      </Column>
    </Wrapper>
  )
}
