import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

const Quote = styled.p`
  font-size: 22px;
  max-width: 640px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
`

const Button = styled(NavLink)`
  padding: 16px 48px;
  background-color: #a2dc4d;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-transform: uppercase;
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  line-height: 1;

  @media (max-width: 1000px) {
    font-size: 32px;
    margin:  0 0 20px;
  }
`

const Subtitle = styled.h2`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin:  24px 0;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
`

export default function Home() {
  return (
    <Wrapper>
      <div>
        <Title><NavLink to='/'>Anna Petruzza</NavLink></Title>
        <Subtitle>Psicologa</Subtitle>
        <Quote>“Questo è uno dei veri scopi della terapia: liberare la gente così che possa investire il proprio coraggio nel vivere. Sperimentare veramente la vita, non solo pensare ad essa”.<br /><br />- Whitaker, 1990</Quote>
        <Button to="/contatti">Contattami</Button>
      </div>
    </Wrapper>
  )
}
