import React, { useEffect } from 'react';
import { Switch, Route, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import bg from './assets/bg.jpg'
import anna from './assets/anna.jpeg'
import Menu from './Menu';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import CookieBanner from './pages/CookieBanner';
import Prismic from 'prismic-javascript'
import { menuItems } from './routes';
import { setApi } from './actions';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  position: relative;
  min-height: ${props => `${props.minHeight}px`};
`

const FixedBg = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: fixed;
  opacity: .3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Grid = styled.div`
  max-width: 1156px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding-top: 80px;

  @media (max-width: 1000px) {
    padding-top: ${props => props.isHome ? '80px' : '12px'};
  }
`

const GridContainer = styled.div`
  margin: 0 16px;
`

const PageBody = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;

  @media (max-width: 1000px) {
    padding: 20px;
  }
`

const Header = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 48px;
  font-size: 18px;

  @media (max-width: 1000px) {
    margin-bottom: 24px;
  }
`

const Info = styled.small`
  margin-top: 24px;
  font-size: 12px;
  line-height: 1.5;
`

const FooterList = styled.ul`
  display: flex;
  list-style: none;
  margin: 48px 0 24px;
  padding: 0;

  @media (max-width: 1000px) {
    flex-direction: column;
    margin: 24px 0 16px;
  }
`

const FooterListItem = styled.li`
  margin: 0 8px;

  .active {
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    margin: 8px 0;
  }
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  line-height: 1;

  @media (max-width: 1000px) {
    font-size: 32px;
  }
`

const Subtitle = styled.h2`
  font-family: 'Rufina';
  font-size: 18px;
  font-weight: normal;
  margin:  0 0 16px;
`
const { hostname, protocol, port } = window.location
const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`

function App() {
  const location = useLocation()
  const isHome = location.pathname === '/'
  const dispatch = useDispatch()

  useEffect(() => {
    const pathname = location.pathname

    if (pathname.substring(1).split('/').length === 1) {
      window.scrollTo(0, 0)
    }

    Prismic.getApi('https://annapetruzza.cdn.prismic.io/api/v2', {
      accessToken: 'MC5YbjJ1N3hJQUFDZ0FxbWdf.77-977-9FBvvv73vv71q77-977-977-977-977-9Ee-_ve-_ve-_ve-_ve-_vWrvv73vv73vv73vv71bJh1seHxQcxA'
    }).then(api => {
      dispatch(setApi(api))
    })

    ReactGA.pageview(pathname);
  }, [location, dispatch])
  return (
    <Wrapper>
      <FixedBg bg={bg}></FixedBg>
      <CookieBanner />
      <Grid isHome={isHome}>
        <GridContainer>
          <Header>
            <div>
              {!isHome && <>
                <Title><NavLink to='/'>Anna Petruzza</NavLink></Title>
                <Subtitle>Psicologa</Subtitle>
              </>}
            </div>
            <Menu />
          </Header>
          <PageBody>
            <Switch>
              {menuItems.map(item => {
                const metaTitle = item.meta.title || `Anna Petruzza - ${item.name}`
                return <Route key={item.route + 'route'} exact={item.exact} path={item.route}>
                  <item.component children={item.children} />
                  {item.name !== 'Post' && <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={item.meta.description} />
                    <meta name="twitter:title" content={metaTitle} />
                    <meta name="twitter:description" content={item.meta.description} />
                    <meta name="twitter:image" content={origin + anna} />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={item.meta.description} />
                    <meta property="og:image" content={origin + anna} />
                  </Helmet>}
                </Route>
              }
              )}
            </Switch>
          </PageBody>
          <Footer>
            <FooterList>
              {menuItems.map(item => item.name !== 'Post' && <FooterListItem key={item.route + 'footer'}><NavLink to={item.route} exact={item.exact}>{item.name}</NavLink></FooterListItem>)}
            </FooterList>
            <div>© {new Date().getFullYear()} • Anna Petruzza </div>
            <Info>C.F.: PTRNNA89D64C352C<br />P.IVA: 03687590798<br />Iscritta all’Albo degli Psicologi della Lombardia N° 21431</Info>
          </Footer>
        </GridContainer>
      </Grid>
    </Wrapper>
  );
}

export default App;
