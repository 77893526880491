import React from "react";
import styled, { css } from "styled-components";
import studio from "../assets/studio.jpg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const Title = styled.h1`
  font-family: "Rufina";
  font-size: 32px;
  font-weight: normal;
  margin: 0 0 30px;

  @media (max-width: 1000px) {
    font-size: 26px;
    margin: 0 0 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 1.5;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

const Quote = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 40px;
  border-radius: 12px;
  line-height: 1.5;
  background-color: #f2f2f2;

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

const Column = styled.div`
  flex: 1;
  &:first-child {
    max-width: 35%;
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    &:first-child {
      margin-right: 0;
      max-width: none;
    }
  }
`;

const Image = styled.img`
  border-radius: 8px;
  width: 100%;

  ${(props) =>
    props.onlyDesktop
      ? css`
          display: block;
          @media (max-width: 1000px) {
            display: none;
          }
        `
      : null}

  ${(props) =>
    props.onlyMobile
      ? css`
          display: none;
          @media (max-width: 1000px) {
            display: block;
            margin-bottom: 24px;
          }
        `
      : null}
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 350px;
  margin-top: 24px;
  border: none;

  @media (max-width: 1000px) {
    margin-top: 16px;
    height: 250px;
  }
`;

const ContactChoiceContainer = styled.div`
  display: flex;
  margin: 30px 0;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ContactChoice = styled.a`
  display: inline-block;
  &:first-child {
    margin-right: 12px;
    margin-left: 0;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
    margin-bottom: 8px;
    &:first-child {
      margin-right: 0;
    }
  }

  margin-left: 12px;
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 12px;

  svg {
    margin: 0 24px 0 12px;
  }
`;

const ContactChoiceTitle = styled.h4`
  margin: 0 0 4px;
  display: flex;
  align-items: center;
`;

const phoneIcon = (
  <svg
    height="18px"
    version="1.1"
    viewBox="0 0 18 18"
    width="18px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="#a2dc4d"
        id="Icons-Communication"
        transform="translate(-85.000000, -126.000000)"
      >
        <g id="phone" transform="translate(85.000000, 126.000000)">
          <path
            d="M3.6,7.8 C5,10.6 7.4,12.9 10.2,14.4 L12.4,12.2 C12.7,11.9 13.1,11.8 13.4,12 C14.5,12.4 15.7,12.6 17,12.6 C17.6,12.6 18,13 18,13.6 L18,17 C18,17.6 17.6,18 17,18 C7.6,18 0,10.4 0,1 C0,0.4 0.4,0 1,0 L4.5,0 C5.1,0 5.5,0.4 5.5,1 C5.5,2.2 5.7,3.4 6.1,4.6 C6.2,4.9 6.1,5.3 5.9,5.6 L3.6,7.8 L3.6,7.8 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

const mailIcon = (
  <svg
    version="1.1"
    viewBox="0 0 16 16"
    width="22px"
    height="22px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="Icons with numbers"
      stroke="none"
      strokeWidth="1"
    >
      <g fill="#bba63d" id="Group" transform="translate(-336.000000, 0.000000)">
        <path
          d="M345.731959,8.48453617 L350.681755,12.7272182 C350.501681,12.8954635 350.259495,13 349.993155,13 L338.006845,13 C337.739189,13 337.496237,12.8970552 337.316068,12.7290845 L342.268041,8.48453617 L344,10.0000001 Z M344,9 L337.318245,3.27278178 C337.498319,3.10453648 337.740505,3 338.006845,3 L349.993155,3 C350.260811,3 350.503763,3.10294483 350.683932,3.27091553 Z M351,12.1856084 L346.167358,8.07885766 L351,3.875422 L351,12.1856084 L351,12.1856084 Z M337,12.1856079 L337,3.87815189 L341.832642,8.07885742 L337,12.1856079 L337,12.1856079 Z M337,12.1856079"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default function Contatti() {
  return (
    <Wrapper>
      <Column>
        <Image src={studio} onlyDesktop alt="Studio" />
        <IFrame
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.5876297171767!2d9.205434615155765!3d45.47811024053681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6c11c5baf25%3A0xbec1e2c93e2d3623!2sVia%20S.%20Gregorio%2C%206%2C%2020124%20Milano%20MI%2C%20Italy!5e0!3m2!1sen!2snl!4v1583095422555!5m2!1sen!2snl"
          frameborder="0"
          allowfullscreen=""
        ></IFrame>
        <Quote>
          "Un viaggio di mille miglia comincia sempre con il primo passo"
          <br />
          <br />- Lao Tzu
        </Quote>
      </Column>
      <Column>
        <Title>Contatti</Title>
        <Paragraph>
          Lo studio si trova a Milano, in via San Gregorio 6, facilmente
          raggiungibile dalle metropolitane <strong>Porta Venezia</strong>{" "}
          (M1-rossa e passante ferroviario), <strong>Repubblica</strong>{" "}
          (M3-gialla), <strong>Centrale</strong> (M2-verde), e dal{" "}
          <strong>tram linee 5, 33, 1</strong>
        </Paragraph>

        <ContactChoiceContainer>
          <ContactChoice href="tel:+393891152861">
            {phoneIcon}
            <div>
              <ContactChoiceTitle>
                <span>Chiama</span>
              </ContactChoiceTitle>
              <div>+39 3891152861</div>
            </div>
          </ContactChoice>
          <ContactChoice href="mailto:annapetruzza1@gmail.com">
            {mailIcon}
            <div>
              <ContactChoiceTitle>Invia una e-mail</ContactChoiceTitle>
              <div>annapetruzza1@gmail.com</div>
            </div>
          </ContactChoice>
        </ContactChoiceContainer>
        <Image src={studio} onlyMobile alt="Studio" />
      </Column>
    </Wrapper>
  );
}
