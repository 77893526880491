import React from 'react'
import PostList from './PostList'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin:  0 0 30px;

  @media (max-width: 1000px) {
    font-size: 26px;
    margin:  0 0 20px;
  }
`

export default function Blog(props) {
  const api = useSelector(state => state.api)

  return (
    <div>
      <Title>Blog</Title>
      {api && <PostList api={api} />}
    </div>
  )
}
