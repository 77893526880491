const initialState = {
  api: false,
  posts: {},
  page: 0,
  query: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_API':
      return { ...state, api: payload }
    case 'SET_POSTS':
      return {
        ...state, posts: payload
      }
    case 'SET_BLOG_QUERY':
      return {
        ...state,
        query: payload
      }

    case 'SET_PAGE':
      return {
        ...state,
        page: payload
      }
    case 'SET_POST':
      return {
        ...state, posts: {
          ...state.posts,
          [payload.uid]: payload
        }
      }

    default:
      return state
  }
}
