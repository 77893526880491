import ListComponent from "./pages/AreeDiIntervento/ListComponent";
import Blog from "./pages/Blog/Blog";
import Contatti from "./pages/Contatti";
import Policy from "./pages/Policy";
import AreeDiIntervento from "./pages/AreeDiIntervento";
import Terapia from "./pages/Terapia";
import ChiSono from "./pages/ChiSono";
import Home from "./pages/Home";
import Post from "./pages/Blog/Post";
import SinglePostView from "./pages/Blog/SinglePostView";

export const menuItems = [
  {
    name: 'Home',
    route: '/',
    component: Home,
    exact: true,
    meta: {
      title: 'Psicologa Anna Petruzza',
      description: 'Anna Petruzza è una psicologa, specializzata in terapia sistemico-relazionale. Riceve presso il suo studio privato a Milano e collabora con scuole, consultori e associazioni.'
    }
  },
  {
    name: 'Chi sono',
    route: '/chi-sono',
    component: ChiSono,
    meta: {
      description: 'Anna Petruzza è una psicologa, specializzata in terapia sistemico-relazionale. Riceve presso il suo studio privato a Milano e collabora con scuole, consultori e associazioni.'
    }
  },
  {
    name: 'Terapia Sistemico-Relazionale',
    route: '/terapia-sistemico-relazionale',
    component: Terapia,
    meta: {
      description: 'Il metodo sistemico relazionale è un modello di psicoterapia secondo cui l\'origine di sintomi e disfunzioni è da ricercare nella storia familiare e relazionale del paziente, e nel modo in cui il sistema in cui la persona è inserita risponde alle diverse richieste di transizione e cambiamento che l\'ambiente pone nel corso del ciclo di vita.'
    }
  },
  {
    name: 'Aree di Intervento',
    route: '/aree-di-intervento',
    component: AreeDiIntervento,
    meta: {
      description: 'La terapeuta Anna Petruzza, adottando il modello sistemico-relazionale, svolge psicoterapia individuale, di coppia e familiare. Il suo intervento si rivolge ai singoli individui nelle diverse fasi della loro vita (infanzia, adolescenza, età adulta e anzianità), alla coppia in crisi e alla famiglia che si trova ad affrontare momenti di difficoltà (lutti, separazioni, adozioni, ecc).'
    },
    children: [
      {
        name: 'Bambini',
        route: '/bambini',
        component: ListComponent,
        list: ['Ansia da separazione',
          'Disturbo oppositivo provocatorio',
          'Problemi ad addormentarsi, incubi',
          'Fobie',
          'Problemi di regolazione: sonno, alimentazione, evacuazione',
          'Problemi comportamentali: aggressività, disobbedienza, bugie',
          'Gestione delle emozioni: ansia, rabbia, tristezza',
          'Disturbo da deficit di attenzione e iperattività (ADHD)',
          'Disturbi dell’apprendimento: DSA, BES, Dislessia, Disgrafia, Discalculia']
      },
      {
        name: 'Adolescenti',
        route: '/adolescenti',
        component: ListComponent,
        list: [
          'Disturbi dell’umore e disturbi d’ansia',
          'Difficoltà relazionali con i coetanei, isolamento',
          'Disagio rispetto al proprio corpo',
          'Problemi scolastici',
          'Difficoltà relazionali con i propri genitori',
          'Disturbi psicosomatici',
          'Disturbi alimentari: anoressia, bulimia, binge eating disorder, obesità',
          'Autolesionismo',
          'Dipendenze'
        ]
      },
      {
        name: 'Adulti',
        route: '/adulti',
        component: ListComponent,
        list: ['Stress, Tecniche di rilassamento, Mindfulness',
          'Gravidanza, Maternità e Depressione Post Partum',
          'Ansia, Attacchi di panico e Fobie',
          'Elaborazione di un lutto',
          'Disturbi dell’umore, depressione e disturbo bipolare',
          'Disturbo ossessivo compulsivo',
          'Dipendenze',
          'Disturbi psicosomatici',
          'Disturbi di personalità',
          'Invecchiamento, menopausa']
      },
      {
        name: 'Coppia',
        route: '/coppia',
        component: ListComponent,
        list: ['Terapia di coppia',
          'Convivenza, Matrimonio',
          'Nascita di un figlio: la genitorialità',
          'Passione, intimità e sessualità',
          'Tradimento',
          'Dipendenza affettiva',
          'Famiglia d’origine e relazione di coppia',
          'Sindrome del nido vuoto',
          'Coppia omosessuale',
          'Coppia mista']
      },
      {
        name: 'Famiglia',
        route: '/famiglia',
        component: ListComponent,
        list: ['Terapia Familiare',
          'Separazione e/o Divorzio',
          'Affido e/o Adozione',
          'Famiglie ricostituite',
          'Famiglie monogenitoriali',
          'Elaborazione del lutto in famiglia']
      }
    ]
  },
  {
    name: 'Blog',
    route: '/blog/:page?',
    defaultRoute: '/blog',
    component: Blog,
    // exact: true,
    meta: {
      description: 'La psicologa Anna Petruzza riceve su appuntamento. È possibile prendere appuntamento chiamando, scrivendo una mail o compilando il format. È possibile concordare con la psicologa percorsi a tariffe calmierate a partire da 40 euro.'
    }
  },
  {
    name: 'Post',
    route: '/post/:uid',
    component: SinglePostView,
    meta: {}
  },
  {
    name: 'Contatti',
    route: '/contatti',
    component: Contatti,
    meta: {
      description: 'La psicologa Anna Petruzza riceve su appuntamento. È possibile prendere appuntamento chiamando, scrivendo una mail o compilando il format. È possibile concordare con la psicologa percorsi a tariffe calmierate a partire da 40 euro.'
    }
  },
  {
    name: 'Policy',
    route: '/cookie-policy',
    component: Policy,
    meta: {
      description: 'Policy di annapetruzza.com. I Cookie sono costituiti da porzioni di codice installate all’interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell’Utente. Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato'
    }
  },
]