import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import benessere from '../../assets/benessere.jpg'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin:  0 0 30px;

  @media (max-width: 1000px) {
    font-size: 26px;
    margin:  0 0 20px;
  }
`

const Quote = styled.div`
  text-align: center;
  line-height: 1.5;
  margin-top: 30px;
  padding: 40px;
  border-radius: 12px;
  background-color: #f2f2f2;

  @media (max-width: 1000px) {
    margin-top: 0;
    padding: 20px;
  }
`

const Column = styled.div`
  flex: 1;
  &:first-child {
    max-width: 35%;
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    &:first-child {
      margin-right: 0;
      max-width: none;
    }
  }
`

const Image = styled.img`
  border-radius: 8px;
  width: 100%;

  ${props => props.onlyDesktop ? css`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  ` : null}

  ${props => props.onlyMobile ? css`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin-bottom: 24px;
  }
  ` : null}
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`

const ListItem = styled.li`
 a:link {
    padding: 12px;
    font-size: 18px;
    border-radius: 8px;
    background-color: #f2f2f2;
    display: block;
    margin: 8px 0;
 }
`

const Paragraph = styled.p`
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 1.5;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`

export default function AreeDiIntervento(props) {
  const { children } = props
  const { path, url } = useRouteMatch();

  return (
    <Wrapper>
      <Column>
        <Image src={benessere} onlyDesktop alt="Benessere" />
        <Quote>“Partendo dai sintomi inizia una ricerca di quei significati relazionali e di quelle connessioni affettive
che permettono un viaggio a ritroso nelle generazioni, per tornare al presente con una visione nuova e propositiva.”
<br /><br />- Maurizio Andolfi, 2015</Quote>
      </Column>
      <Column>
        <Title>Aree di Intervento</Title>
        <Image src={benessere} onlyMobile alt="Benessere" />
        <Paragraph>L'approccio sistemico-relazionale consente al terapeuta di accogliere e prendersi cura delle singole persone, della coppia e della famiglia nei diversi momenti del loro ciclo di vita. Ogni momento della vita, infatti, è contraddistinto da continue e diverse richieste di cambiamento e riadattamento. Il modo in cui la persona e il sistema in cui questa è inserita rispondono alle transizioni e alle richieste dell'ambiente, può alle volte dar luogo all'insorgere di sintomi e/o difficoltà relazionali.</Paragraph>
        <Paragraph>In quest'ottica, il problema o il sintomo che la persona presenta viene letto come un tentativo disperato di mantenere l'equilibrio e rimanere a galla come meglio si può. È proprio per questa sua profonda valenza che il sintomo diventa poi la porta d'accesso al mondo interiore e relazionale del paziente, ciò che guiderà terapeuta e paziente in un importante viaggio insieme.</Paragraph>
        <List>
          {children.map(item => <ListItem key={item.route} path={`${path}${item.route}`}>
            <NavLink to={`${url}${item.route}`}>{item.name}</NavLink>
            <Route path={`${path}${item.route}`}><item.component list={item.list} /></Route>
          </ListItem>)}
        </List>
      </Column>
    </Wrapper>
  )
}
