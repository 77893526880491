export const setApi = payload => ({
  type: 'SET_API',
  payload
})

export const setPosts = payload => ({
  type: 'SET_POSTS',
  payload
})

export const setPost = payload => ({
  type: 'SET_POST',
  payload
})

export const setPage = payload => ({
  type: 'SET_PAGE',
  payload
})

export const setBlogQuery = payload => ({
  type: 'SET_BLOG_QUERY',
  payload
})