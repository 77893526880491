import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Post from './Post'
import { setPost } from '../../actions'

export default function SinglePostView(props) {
  const propsPost = props.post
  const api = useSelector(state => state.api)
  const { uid } = useParams()
  const post = useSelector(state => state.posts[uid])
  const dispatch = useDispatch()
  const [is404, setIs404] = useState(false)

  useEffect(() => {
    if (api && !post) {
      api.getByUID('post', uid).then(document => {
        console.log('fetch single', document)
        if (document) {
          dispatch(setPost(document))
        } else {
          setIs404(true)
        }
      })
    }
  }, [api, uid, propsPost, dispatch, post])

  if (is404) {
    return '404'
  }

  if (!post) {
    return null
  }

  return (
    <div>
      <Post post={post} />
    </div>
  )
}
