import React, { useEffect, useRef, useState } from 'react'
import Post from './Post'
import { useDispatch, useSelector } from 'react-redux'
import { setPosts, setBlogQuery, setPage } from '../../actions'
import Pagination from './Pagination'
import { useParams } from 'react-router-dom'

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function PostList({ api }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const posts = useSelector(state => state.posts)
  const reduxPage = useSelector(state => state.page)
  const query = useSelector(state => state.query)
  const { page = 1 } = useParams()
  const pageParamNr = parseInt(page)
  const postsUids = Object.keys(posts)
  const hasPosts = !!postsUids.length
  const iterablePosts = postsUids.map(uid => posts[uid])
  const prevPage = usePrevious(page)
  const showPagination = query && query.total_pages > 1

  useEffect(() => {
    dispatch(setPage(pageParamNr))
    if (api && pageParamNr !== prevPage && reduxPage !== pageParamNr) {
      setLoading(true)
      api.query('', { pageSize: 10, page: pageParamNr }).then(documents => {
        console.log('get list of post', documents.results)

        dispatch(setBlogQuery(documents))
        const posts = {}

        documents.results.forEach(post => {
          posts[post.uid] = post
        })
        dispatch(setPosts(posts))
        setLoading(false)
      }).catch(() => setLoading(false))
    }
  }, [api, dispatch, query, pageParamNr, prevPage, reduxPage])

  if (loading) {
    return <div>Caricando gli articoli...</div>
  }

  return (
    <div>
      {!hasPosts && <div>Non ci sono articoli</div>}
      {hasPosts && iterablePosts.map(post => <Post key={post.id} post={post} short />)}
      {showPagination && <Pagination totalPages={query.total_pages} currentPage={page} />}
    </div>
  )
}
