import React from 'react'
import PrismicDOM from 'prismic-dom'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import placeholder from '../../assets/placeholder.png'
import Helmet from 'react-helmet'
import { DateTime } from "luxon"

const ShortWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;
  font-size: 18px;

  @media (max-width: 1000px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`

const ShortTitle = styled.h2`
  font-family: 'Rufina';
  font-size: 26px;
  font-weight: normal;
  margin:  0;

  @media (max-width: 1000px) {
    font-size: 22px;
  }
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
`

const ShortImageContiner = styled.img`
  width: 220px;
  min-width: 220px;
  height: 180px;
  margin-right: 30px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;

  ${props => props.onlyDesktop ? css`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  ` : null}

  ${props => props.onlyMobile ? css`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
  ` : null}
`

const Column = styled.div`
  flex: 1;
  &:first-child {
    max-width: 35%;
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    &:first-child {
      margin-right: 0;
      max-width: none;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;

  @media (max-width: 1000px) {
    font-size: 16px;
    flex-direction: column-reverse;
  }
`

const Image = styled.img`
  border-radius: 8px;
  width: 100%;

  ${props => props.onlyDesktop ? css`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  ` : null}

  ${props => props.onlyMobile ? css`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin-bottom: 24px;
  }
  ` : null}
`

const ShortLink = styled(NavLink)`
  display: flex;
  align-items: center;

  &:visited, &:link {
    color: #6bb500;
  }
  svg {
    margin-left: 8px;
    fill: #6bb500;
  }
`

const PostInfo = styled.small`
  margin:  0 0 30px;
  display: block;

  @media (max-width: 1000px) {
    margin:  0 0 20px;
  }
`

const Description = styled.div`
  line-height: 1.5;

  a {
    color: #6bb500;
  }

  h3 {
    font-family: 'Rufina';
    font-size: 24px;
    font-weight: normal;
    margin:  0;

    @media (max-width: 1000px) {
      font-size: 20px;
    }

    strong {
      font-weight: inherit;
    }
  }

  img,
  iframe {
    width: 100%;
  }

  iframe {
    max-height: 400px;
    height: 50vw;
  }
`
const { hostname, protocol, port } = window.location
const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`
const arrow = <svg height="12" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.995 511.995" xmlSpace="preserve">
  <path d="M381.039,248.62L146.373,3.287c-4.083-4.229-10.833-4.417-15.083-0.333c-4.25,4.073-4.396,10.823-0.333,15.083
  L358.56,255.995L130.956,493.954c-4.063,4.26-3.917,11.01,0.333,15.083c2.063,1.979,4.729,2.958,7.375,2.958
  c2.813,0,5.604-1.104,7.708-3.292L381.039,263.37C384.977,259.245,384.977,252.745,381.039,248.62z" />
</svg>

export default function Post(props) {
  const post = props.post
  const title = post.data.title[0].text
  const image = post.data.image
  const imageUrl = image.url || placeholder
  const metaDescription = post.data.meta_description[0].text
  const date = DateTime.fromISO(post.first_publication_date, { locale: "it" }).toLocaleString(DateTime.DATE_FULL)

  if (props.short) {
    return <ShortWrapper>
      {imageUrl && <NavLink to={`/post/${post.uid}`}><ShortImageContiner src={imageUrl} alt={image.alt} onlyDesktop /></NavLink>}
      <div>
        <ShortTitle><NavLink to={`/post/${post.uid}`}>{title}</NavLink></ShortTitle>
        <PostInfo>{date}</PostInfo>
        {imageUrl && <NavLink to={`/post/${post.uid}`}><ShortImageContiner src={imageUrl} alt={image.alt} onlyMobile /></NavLink>}
        <Description dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(post.data.short_body) }}></Description>
        <ShortLink to={`/post/${post.uid}`}>Approfondisci {arrow}</ShortLink>
      </div>
    </ShortWrapper>
  }

  return (
    <Wrapper>
      <Helmet>
        {title}
        <title>Anna Petruzza - {title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={origin + imageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={origin + imageUrl} />
      </Helmet>
      <Column>
        <Image src={imageUrl} onlyDesktop alt={image.alt} />
      </Column>
      <Column>
        <Title>{title}</Title>
        <PostInfo>{date}</PostInfo>
        <Image src={imageUrl} onlyMobile alt={image.alt} />
        <Description dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(post.data.body) }}></Description>
      </Column>
    </Wrapper>
  )
}
