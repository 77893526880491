import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 20px 0;
  box-shadow: 0px -4px 4px #e1f0ca;
`

const Container = styled.div`
  max-width: 1156px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
`

const TextContainer = styled.div`
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
`

const Text = styled.p`
  flex: 1;
  max-width: 90%;
  margin: 0 8px 0;
  font-size: 14px;
  line-height: 1.5;

  a {
    color: #6bb500;
  }
`

const Button = styled.button`
  align-self: flex-start;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  line-height: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const hasBannerFlag = !!localStorage.getItem('banner-closed')

export default function CookieBanner() {
  const [hideBanner, setHideBanner] = useState(hasBannerFlag)

  const onClose = () => {
    setHideBanner(true)
    localStorage.setItem('banner-closed', 1)
  }

  if (hideBanner) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <Text>Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella <NavLink to='/cookie-policy' onClick={onClose}>cookie policy</NavLink>. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la cookie policy. Chiudendo questo banner, scorrendo questa pagina, cliccando su un link o proseguendo la navigazione in altra maniera, acconsenti all’uso dei cookie.</Text>
          <Button onClick={onClose}>Chiudi</Button>
        </TextContainer>
      </Container>
    </Wrapper>
  )
}
