import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { menuItems } from './routes'

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 22px 0 0;
  display: flex;

  @media (max-width: 1000px) {
    bottom: 0;
    background: white;
    top: 0;
    right: 0;
    padding-top: 120px;
    flex-direction: column;
    position: fixed;
    box-shadow: -3px 0px 4px #c7d5bd;
    transition: all ease-out .3s;
    ${props => props.menuOn ? css`
      transform: translate3d(20px, 0, 0);
  ` : css`
    transform: translate3d(300px, 0, 0);
  `}
  }
`

const ListItem = styled.li`
  margin-right: 8px;
`

const ListItemLink = styled(NavLink)`
  padding: 8px 12px;
  background-color: white;
  display: inline-block;
  border-radius: 4px 4px 0 0;
  opacity: .5;
  height: 100%;

  &.active {
    opacity: 1;
  }

  @media (max-width: 1000px) {
    padding: 8px 12px 8px 24px;
    height: auto;
  }
`

const Button = styled.button`
  outline: none;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  line-height: 1px;
  border: none;
  position: fixed;
  right: 10px;
  top: 20px;
  border-radius: 50%;
  box-shadow: 0px 3px 4px #c7d5bd;
  display: none;

  @media (max-width: 1000px) {
    display: block
  }
`

const hamburger = <svg height="18px" viewBox="0 -53 384 384" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg>

export default function Menu() {
  const [menuOn, setMenuOn] = useState(false)
  return (
    <nav>
      <Button onClick={() => setMenuOn(!menuOn)}>{hamburger}</Button>
      <List menuOn={menuOn}>
        {menuItems.map(item => {
          if (item.name === 'Policy' || item.name === 'Post') {
            return null
          }

          const isActive = (match, location) => {
            if (item.name === 'Blog' && location.pathname.includes('post')) {
              return true
            }

            if (!match) {
              return false
            }

            return true
          }

          return <ListItem key={item.route}>
            <ListItemLink to={item.defaultRoute || item.route} isActive={isActive} exact={item.exact} onClick={() => setMenuOn(false)}>{item.name}</ListItemLink>
          </ListItem>
        })}
      </List>
    </nav>
  )
}
