import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  margin-bottom: 30px;
`

const ListItem = styled.li`
  font-size: 18px;
  line-height: 1.5;
`

export default function ListComponent(props) {
  const { list } = props
  return (
    <List>
      {list.map((item, index) => <ListItem key={item + index}>{item}</ListItem>)}
    </List>
  )
}
