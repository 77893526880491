import React from 'react'
import styled, { css } from 'styled-components'
import women from '../assets/women.jpg'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`

const Title = styled.h1`
  font-family: 'Rufina';
  font-size: 32px;
  font-weight: normal;
  margin:  0 0 30px;

  @media (max-width: 1000px) {
    font-size: 26px;
    margin:  0 0 20px;
  }
`

const Paragraph = styled.p`
  font-size: 18px;
  margin: 0 0 24px;
  line-height: 1.5;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`

const Quote = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 40px;
  border-radius: 12px;
  line-height: 1.5;
  background-color: #f2f2f2;

  @media (max-width: 1000px) {
    margin-top: 0;
    padding: 20px;
  }
`

const Column = styled.div`
  flex: 1;
  &:first-child {
    max-width: 35%;
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    &:first-child {
      margin-right: 0;
      max-width: none;
    }
  }
`

const Image = styled.img`
  border-radius: 8px;
  width: 100%;

  ${props => props.onlyDesktop ? css`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  ` : null}

  ${props => props.onlyMobile ? css`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin-bottom: 24px;
  }
  ` : null}
`

export default function Terapia() {
  return (
    <Wrapper>
      <Column>
        <Image src={women} onlyDesktop alt="Terapia" />
        <Quote>“Ho sviluppato nel tempo la convinzione che la famiglia sia la miglior medicina; la cura consiste allora nel ripercorrere insieme la sua storia di sviluppo, facendo rimarginare ferite ancora aperte e ricomporre rapporti affettivi spezzati. Il problema presentato diventa una porta d’accesso al mondo familiare e il paziente una guida privilegiata nell’esplorazione dei legami familiari.”<br /><br />- Maurizio Andolfi, 2015</Quote>
      </Column>
      <Column>
        <Title>Terapia Sistemico-Relazionale</Title>
        <Image src={women} onlyMobile alt="Terapia" />
        <Paragraph>Il mio orientamento teorico di riferimento è quello sistemico - relazionale. J. Donne prima, e T. Merton poi scrivevano: “Nessun uomo è un’isola; ogni uomo è un pezzo del continente, una parte del tutto”.</Paragraph>
        <Paragraph>Ogni uomo vive, infatti, immerso nelle sue relazioni, influenzandole e venendone influenzato a sua volta. Pertanto, per poter comprendere al meglio il vissuto che ogni individuo porta in stanza di terapia, è necessario “allargare” il campo visivo, includendo nella nostra mente le relazioni significative della sua vita e ripercorrendo insieme la sua storia.</Paragraph>
        <Paragraph>Secondo l’approccio sistemico, le relazioni possono rappresentare, quindi, in base alla loro qualità, flessibilità e capacità di cambiamento, un limite oppure un’enorme risorsa per gli individui. In quest’ottica, il comportamento sintomatico può essere letto come un tentativo che la persona mette in atto al fine di mantenere a tutti i costi un doloroso equilibrio.</Paragraph>
        <Paragraph>L'intervento terapeutico si basa dunque sull’osservazione delle modalità di relazione tra il paziente e la sua famiglia e mira a modificare i modelli disfunzionali presenti nel contesto entro il quale il disagio del paziente è emerso, stimolando le risorse familiari e rafforzando sia il funzionamento individuale sia quello familiare.</Paragraph>
      </Column>
    </Wrapper>
  )
}
